// eslint-disable-next-line no-undef
const ApiUrl = process.env.NEXT_PUBLIC_API_URL;

const UrlParamsReplace = (url, params = {}) => {
  let urlWithPrefix = `${ApiUrl}${url}`;
  if (params) {
    Object.keys(params).forEach(
      (key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]))
    );
  }
  return urlWithPrefix;
};

//aws
export const GET_PRE_SIGNED_URL = UrlParamsReplace("/aws/get-pre-signed-url");
export const GET_UPLOAD_PRE_SIGNED_URL = UrlParamsReplace(
  "/aws/get-upload-pre-signed-url"
);

// user sessions
export const USER_SESSION = UrlParamsReplace("/user-sessions");
export const URER_LOGIN = UrlParamsReplace("/v2/login/parent");
export const UPDATE_USER = (userId) =>
  UrlParamsReplace("/users/:userId", { userId });

// parents
export const GET_PARENT = (userId) =>
  UrlParamsReplace("/parents/:userId", { userId });
export const GET_PARENT_BTB = (userId) =>
  UrlParamsReplace("/parents-btb/:userId", { userId });
// kids
export const ADD_KID = UrlParamsReplace("/kids");
export const CREATE_PAYMENT_GATEWAY_ORDER = (parentId, kidId) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/transactions/add-money", {
    parentId,
    kidId,
  });
export const GET_KID_WALLET = (parentId, kidId) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/wallets/", {
    parentId,
    kidId,
  });
export const GET_KID_WALLET_COUPON = (parentId, kidId, type) =>
  UrlParamsReplace("/v3/parents/:parentId/kids/:kidId/coupons?type=:type", {
    parentId,
    kidId,
    type
  });
export const GET_All_WALLETS = (parentId, kidId) =>
  UrlParamsReplace("/parents/:parentId/wallets", {
    parentId,
  });
export const GET_ALL_RAISED_REQUESTS = (parentId) =>
  UrlParamsReplace("/parents/:parentId/refund/requests", {
    parentId,
  });
export const TRANSFER_WALLET_BALANCE = (parentId) =>
  UrlParamsReplace("/parents/:parentId/wallet/transfer", {
    parentId,
  });
export const RAISE_REFUND_REQUEST = (parentId, kidId) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/refund/request", {
    parentId,
    kidId,
  });
export const GET_KID_DETAILS = (parentId, kidId) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/details", {
    parentId,
    kidId,
  });
export const UPDATE_KID_DETAILS = (kidId) =>
  UrlParamsReplace("/kids/:kidId/update", { kidId });
export const VALIDATE_REFERRAL = () =>
  UrlParamsReplace("/referral/validate", {});
export const ADD_MONEY_AND_PLACE_ORDER = (parentId, kidId) =>
  UrlParamsReplace(
    "/parents/:parentId/kids/:kidId/transactions/add-money-place-order",
    { parentId, kidId }
  );

// schools
export const GET_SCHOOLS = (userId, offset, limit, search, schoolArea) =>
  UrlParamsReplace(
    "/parents/:userId/schools?offset=:offset&limit=:limit&search=:search&schoolArea=:schoolArea",
    { userId, offset, limit, search, schoolArea }
  );
export const GET_SCHOOLA_AREA_LIST = (userId) =>
  UrlParamsReplace("/parents/:userId/school-area", { userId });

export const CREATE_SCHOOL_ONBOARD_REQUEST = (userId) =>
  UrlParamsReplace("/v3/parents/:userId/school-onboard-request", { userId });

// menu
export const GET_MENU_DATES = (parentId, kidId) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/menus", { parentId, kidId });
export const GET_MENU_DATES_V3 = (parentId, kidId) =>
  UrlParamsReplace("/v3/parents/:parentId/kids/:kidId/menus", { parentId, kidId });
export const GET_MENU_DATA = (parentId, kidId, date) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/subscription/menus/:date", {
    parentId,
    kidId,
    date,
  });
export const GET_MENU_DATA_V3 = (parentId, kidId, date) =>
  UrlParamsReplace("/v3/parents/:parentId/kids/:kidId/subscription/menus/:date", {
    parentId,
    kidId,
    date,
  });
export const GET_MENU_DATA_FOR_NO_KID_V3 = (parentId, date) =>
  UrlParamsReplace("/v3/parents/:parentId/menus/:date", {
    parentId,
    date,
  });
export const GET_MENU_DATA_B2B_SCHOOL = (parentId, schoolId, date) =>
  UrlParamsReplace("/parents-btb/:parentId/menu/:date?schoolId=:schoolId", {
    parentId,
    date,
    schoolId,
  });
export const GET_ORDER_REPORT_B2B_SCHOOL = (
  parentId,
  schoolId,
  date,
  monthly
) => {
  if (monthly) {
    return UrlParamsReplace(
      "/school-btb/:parentId/orderreports/schools/:schoolId/:date?monthly=:monthly",
      {
        parentId,
        schoolId,
        date,
        monthly,
      }
    );
  } else {
    return UrlParamsReplace(
      "/school-btb/:parentId/orderreports/schools/:schoolId/:date",
      {
        parentId,
        schoolId,
        date,
      }
    );
  }
};
export const GET_MENU_DATA_B2B2C_SCHOOL = (parentId, schoolId, date, endDate) => {
  if (endDate) {
    return UrlParamsReplace("/parent-btbtc/:parentId/school-btbtc/:schoolId/plan-menu?startDate=:date&endDate=:endDate", {
      parentId,
      date,
      schoolId,
      endDate
    });    
  } else {
    return UrlParamsReplace("/parent-btbtc/:parentId/school-btbtc/:schoolId/plan-menu?startDate=:date", {
      parentId,
      date,
      schoolId,
    });    
  }
}
export const GET_ORDERS_B2B2C_SCHOOL = (
  parentId,
  schoolId,
  date,
  endDate
) => {
  if (endDate) {
    return UrlParamsReplace(
      "/school-btbtc/:parentId/schools/:schoolId/order?startDate=:date&endDate=:endDate",
      {
        parentId,
        schoolId,
        date,
        endDate
      }
    );
  }else {
    return UrlParamsReplace(
      "/school-btbtc/:parentId/schools/:schoolId/order?startDate=:date",
      {
        parentId,
        schoolId,
        date
      }
    );
  }
};
export const GIVE_FEEDBACK = (parentId,kidId) =>
  UrlParamsReplace("/v3/parents/:parentId/kids/:kidId/feedback", { parentId,kidId });

export const SKIP_ORDER_FEEDBACK = (parentId,kidId, date) =>
  UrlParamsReplace("/v3/parents/:parentId/kids/:kidId/orders/:date/skip-feedback", { parentId,kidId, date });

export const GET_UNREATED_ORDERS = (parentId, kidId) =>
  UrlParamsReplace("/v3/parents/:parentId/kids/:kidId/unrated/orders", {
    parentId,
    kidId,
  });
export const GET_FEEDBACK_ITEMS = (userId, kidId, orderId) =>
  UrlParamsReplace("/parents/:userId/kids/:kidId/orders/:orderId/feedback", {
    userId,
    kidId,
    orderId,
  });
export const GET_ORDER_BY_DATE = (parentId, kidId, date) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/orders/date/:date", {
    parentId,
    kidId,
    date,
  });
export const GET_MENU_DATES_BTB = (parentId, kidId) =>
  UrlParamsReplace("/parents-btb/:parentId/kids/:kidId/menus", {
    parentId,
    kidId,
  });
export const GET_MENU_DATA_BTB = (parentId, kidId, date) =>
  UrlParamsReplace("/parents-btb/:parentId/kids/:kidId/menus/:date", {
    parentId,
    kidId,
    date,
  });
export const SUBMIT_MENU_FEEDBACK = (userId, kidId) =>
  UrlParamsReplace("/parents/:userId/kids/:kidId/feedback", { userId, kidId });
export const GET_MENU_DETAILS = (kidId, menuId) =>
  UrlParamsReplace("/kids/:kidId/menus/:menuId", { kidId, menuId });
export const GET_CUISINES = UrlParamsReplace("/cuisines");

// cart
export const KID_CART = (parentId, kidId, date) =>
  UrlParamsReplace("/V3/parents/:parentId/kids/:kidId/carts/date/:date", {
    parentId,
    kidId,
    date,
  });

export const ADD_KID_CART = (parentId, kidId) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/carts", {
    parentId,
    kidId,
  });
export const KID_CART_PRICE = (parentId, kidId, date) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/carts/prices/:date", {
    parentId,
    kidId,
    date,
  });
export const KID_CART_REMOVE_ITEM = (parentId, kidId, menuId) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/carts/:menuId", {
    parentId,
    kidId,
    menuId,
  });

// transactions
export const GET_TRANSACTION = (parentId, transactionId) =>
  UrlParamsReplace("/parents/:parentId/transactions/:transactionId", {
    parentId,
    transactionId,
  });
export const GET_TRANSACTIONS = (
  parentId,
  kidId,
  offset,
  limit,
  transactionType
) =>
  UrlParamsReplace(
    "/parents/:parentId/kids/:kidId/transactions?offset=:offset&limit=:limit&transactionType=:transactionType",
    { parentId, kidId, offset, limit, transactionType }
  );
export const GET_LAST_TRANSACTION = (
  parentId,
  kidId,
  transactionType,
  transactionWith,
  transactionStatus
) =>
  UrlParamsReplace(
    "/parents/:parentId/kid/:kidId/transactions/last?transactionType=:transactionType&transactionWith=:transactionWith&transactionStatus=:transactionStatus",
    { parentId, kidId, transactionType, transactionWith, transactionStatus }
  );
export const CHECK_TRANSACTION_ORDER_STATUS = (parentId, pgTransactionId) =>
  UrlParamsReplace("/parents/:parentId/checktransactions/:pgTransactionId", {
    parentId,
    pgTransactionId,
  });

// orders
export const GET_ACTIVE_ALL_ORDDERSV3 = (parentId, kidId) =>
  UrlParamsReplace("/v3/parents/:parentId/kids/:kidId/orders?type=active", {
    parentId,
    kidId,
  });
  export const GET_PAST_ALL_ORDDERSV3 = (parentId, kidId, offset, limit) =>
    UrlParamsReplace(
      "/v3/parents/:parentId/kids/:kidId/orders?offset=:offset&limit=:limit&type=past",
      {
        parentId,
        kidId,
        offset, // Correctly bind offset
        limit,  // Correctly bind limit
      }
    );
export const CREATE_AUTO_ORDER = (parentId, kidId) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/autoOrders", {
    parentId,
    kidId,
  });
export const GET_ORDER_STATUS = (parentId, kidId, orderId) =>
  UrlParamsReplace("/v3/parents/:parentId/kids/:kidId/orders/:orderId/status", {
    parentId,
    kidId,
    orderId
  });

export const GET_ORDER_PAYMENT_STATUS = (parentId, transactionId) =>
  UrlParamsReplace("/parents/:parentId/checktransactions/:transactionId", {
    parentId,
    transactionId
  });
export const GET_ACTIVE_ORDERS = (parentId, kidId) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/orders/active", {
    parentId,
    kidId,
  });
export const GET_CANCELLED_ORDERS = (parentId, kidId, date) =>
  UrlParamsReplace(
    "/parents/:parentId/kids/:kidId/orders/cancelled/date/:date",
    {
      parentId,
      kidId,
      date,
    }
  );
export const GET_PAST_ORDERS = (parentId, kidId, fromDate) =>
  UrlParamsReplace(
    "/parents/:parentId/kids/:kidId/orders/past?fromDate=:fromDate",
    { parentId, kidId, fromDate }
  );
export const CREATE_ORDER = (parentId, kidId, date) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/orders/date/:date", {
    parentId,
    kidId,
    date,
  });
export const ADD_MENU_ORDER = (parentId, kidId) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/orders/subscription", {
    parentId,
    kidId,
  });
export const ADD_V2_MENU_ORDER = (parentId, kidId) =>
  UrlParamsReplace("/v2/parents/:parentId/kids/:kidId/orders/subscription", {
    parentId,
    kidId,
  });
export const UPGRADE_MENU_ORDER = (parentId, kidId) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/orders/upgrade", {
    parentId,
    kidId,
  });
export const CANCEL_ORDER = (parentId, kidId, orderId) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/orders/:orderId", {
    parentId,
    kidId,
    orderId,
  });
export const CANCEL_ORDER_STATUS = (parentId, kidId, date) =>
  UrlParamsReplace(
    "/parents/:parentId/kids/:kidId/cancelAll/confirm?date=:date",
    {
      parentId,
      kidId,
      date,
    }
  );
export const UPDATE_AUTO_ORDER = (parentId, kidId) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/update", {
    parentId,
    kidId,
  });
export const GET_AUTO_ORDER = (parentId, kidId) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/autoOrder", {
    parentId,
    kidId,
  });
export const FETCH_ORDER_HISTORY = (parentId, kidId) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/orders/past", {
    parentId,
    kidId,
  });
export const UPDATE_ORDER = (parentId, kidId, date) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/edit-order/:date", {
    parentId,
    kidId,
    date,
  });
export const VERIFY_COUPON = (parentId, kidId) =>
  UrlParamsReplace("/v3/parents/:parentId/kids/:kidId/verify-coupon", {
    parentId,
    kidId,
  });
export const CANCEL_ALL_ORDERS = (parentId, kidId, date, status) =>
  UrlParamsReplace(
    "/parents/:parentId/kids/:kidId/cancelAll?date=:date&status=:status",
    { parentId, kidId, date, status }
  );
export const GET_PAST_SEVEN_DAYS_ORDERS = (parentId, kidId, fromDate) =>
  UrlParamsReplace(
    "/parents/:parentId/kids/:kidId/orders/pastOrders?fromDate=:fromDate",
    { parentId, kidId, fromDate }
  );
export const CREATE_ORDER_BTB_KIDS = (parentId, kidId) =>
  UrlParamsReplace("/parents-btb/:parentId/kids/:kidId/orders", {
    parentId,
    kidId,
  });
export const GET_SETTINGS = (userId) =>
  UrlParamsReplace("/parents/:userId/settings", { userId });

export const SCHOOL_B2B2C_PLACE_ORDER = (parentId) =>
  UrlParamsReplace("/school-btbtc/:parentId/orders", {
    parentId,
  });
export const SCHOOL_B2B_PLACE_ORDER = (parentId) =>
  UrlParamsReplace("/school-btb/:parentId/orders", {
    parentId,
  });
export const SCHOOL_B2B_CANCEL_ORDER = (parentId) =>
  UrlParamsReplace("/school-btb/:parentId/orders/b2b-delete", {
    parentId,
  });
export const CANCEL_ALL_ORDERS_BTB = (parentId, kidId, date) =>
  UrlParamsReplace("/parents-btb/:parentId/kids/:kidId/cancelAll?date=:date", {
    parentId,
    kidId,
    date,
  });
// plans

export const GET_PLANS = (kidId, searchPlan = "") =>
  UrlParamsReplace("/v3/kids/:kidId/plans?search=:searchPlan", {
    kidId,
    searchPlan,
  });

export const GET_PLANS_WITHOUT_AUTH = (kidId, searchPlan = "") =>
  UrlParamsReplace("/v3/kids/:kidId/plans/all?search=:searchPlan", {
    kidId,
    searchPlan,
  });

export const GET_PLAN_DETAILS = (kidId, planId) =>
  UrlParamsReplace("/kids/:kidId/plans/:planId", { kidId, planId });

// http://localhost:3000/kids/65e415ab-fb87-4921-bad8-abc7da55fe6a/subscriptions
export const GET_KIDS_PLANLIST = (parentId, kidId) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/subscriptions", {
    parentId,
    kidId,
  });

// Cancel suscription
// parents/d91b376e-3bdd-4437-8708-14f4b0fe85a6/kids/923c8f04-7053-4421-9cc4-d713b8a466d5/subscription/08ebd956-569d-4003-925f-7a3446601626/cancel
export const CANCEL_SUSCRIPTION = (parentId, kidId, subscriptionId) =>
  UrlParamsReplace(
    "/parents/:parentId/kids/:kidId/subscription/:subscriptionId/cancel",
    {
      parentId,
      kidId,
      subscriptionId,
    }
  );

export const CREATE_SUSCRIPTION = (parentId, kidId) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/subscription/subscribe", {
    parentId,
    kidId,
  });
export const CHECK_SUSCRIPTION = (parentId, kidId) =>
  UrlParamsReplace(
    "/parents/:parentId/kids/:kidId/subscription/check-active-subscription",
    {
      parentId,
      kidId,
    }
  );
export const CHECK_ACTIVE_ORDERS = (parentId, kidId, date) =>
  UrlParamsReplace("/parents/:parentId/kids/:kidId/subscription/menus/:date", {
    parentId,
    kidId,
    date,
  });
// /parents/:parentId/kids/:kidId/orders/subscription/status?subscriptionId=:subscriptionId&date=:date
export const GET_SUSCRIPTION_STATUS = (parentId, kidId, subscriptionId, date) =>
  UrlParamsReplace(
    "/parents/:parentId/kids/:kidId/orders/subscription/status?subscriptionId=:subscriptionId&date=:date",
    {
      parentId,
      kidId,
      subscriptionId,
      date,
    }
  );

export const CHANGE_MEALPLAN_STATUS = (parentId, kidId, status) =>
  UrlParamsReplace(
    "/parents/:parentId/kids/:kidId/orders/subscription/status",
    {
      parentId,
      kidId,
      status,
    }
  );

export const ADD_MONEY_SUBSCRIBE_PLAN = (parentId, kidId) =>
  UrlParamsReplace(
    "/parents/:parentId/kids/:kidId/transactions/add-money-subscribe-plan",
    {
      parentId,
      kidId,
    }
  );

// '/parents/:parentId/checksubtransactions/:pgTransactionId'

export const CHECKSUBTRANSACTION_URL = (parentId, pgTransactionId) =>
  UrlParamsReplace("/parents/:parentId/checksubtransactions/:pgTransactionId", {
    parentId,
    pgTransactionId,
  });

// referral Apis

export const GET_REFERRAL_HISTORY = (parentId) =>
  UrlParamsReplace("/referral/parents/:parentId/history", { parentId });

export const GET_REFERRAL_CODE = (parentId) =>
  UrlParamsReplace("/referral/parents/:parentId", { parentId });

export const GET_KID_DETAILS_WITH_SUBSCRIPTION_INFO = (parentId) =>
  UrlParamsReplace("/parents/:parentId/kids-subscriptions", { parentId });

// https://qaapi.monkeybox.in/parents/8c5ec8ee-fc5f-4232-849c-a4ec3b1ed3ac/kids-subscriptions
// parents/:parentId/kids/:kidId/edit-order

// mb/parent-feedbacks

export const GET_RATTING_INFO = (userId) =>
  UrlParamsReplace("/mb/parent-feedbacks", { userId });

export const GET_PARENT_FEEDBACK_INFO = () => {
  return UrlParamsReplace("/mb/loading-feedbacks", {});
};

// mb/loading-feedbacks

export const GET_FEEFBACK = (userId) =>
  UrlParamsReplace("/mb/loading-feedbacks", { userId });

export const GET_SETTINGS_FEEDBACK = () =>
  UrlParamsReplace("/mb/load/settings-feedbacks", { });

export const DELETE_USER = (parentId) =>
  UrlParamsReplace("/users/:parentId", {
    parentId,
  });

// get in touch user 
export const GET_IN_TOUCH_REQUEST = () =>
  UrlParamsReplace("/v3/parents/contact-us", {});

// Promotion 
export const GET_KIDS_PROMOTION_LIST = (userId, kidId, pageType) =>
  UrlParamsReplace("/v3/parents/:userId/kids/:kidId/promotions?pagetype=:pageType", {userId, kidId, pageType});
